@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        src: url("Poppins-Regular.ttf");
    }
    @font-face {
        font-family: "Poppins";
        font-weight: 600;
        font-style: italic;
        src: url("Poppins-SemiBoldItalic.ttf");
    }
    html {
        font-family: "Poppins", sans-serif;
    }
}
